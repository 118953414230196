/* eslint-disable no-return-assign */
import NewOffers from '../auto/stores/NewOffers';
import UsedVehicles from '../auto/stores/UsedVehicles';
import DDMOffers from '../auto/stores/DDMOffers';
import DDMOffersFilter from '../auto/stores/DDMOffersFilter';
import VersionComparison from '../auto/stores/VersionComparison';

(function () {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  const store = {
    NewOffers,
    UsedVehicles,
    DDMOffers,
    DDMOffersFilter,
    VersionComparison,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));
})();
