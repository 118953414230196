import fetchCached from './fetchCached';

/**
 * Service for New's Offers API
 */
class AutoServiceUsedVehiclesApi {
  /**
   * Endpoint no contexto de canal
   *
   * @param {string} suffixEndpoint Sufixo do endpoint da request
   * @returns {string} Retorna o endpoint completo
   */
  getChannelEndpoint(suffixEndpoint) {
    const channelId = document.querySelector('meta[name="auto_api_channel"]')
      .content;

    return `/channel/${channelId}${suffixEndpoint}`;
  }

  /**
   * Return all available plans for subscription
   * @return {Promise}
   */
  getVehicles(customParams = []) {
    const result = fetchCached(
      this.getChannelEndpoint('/used_models'),
      customParams
    );
    return result;
  }
}

export default new AutoServiceUsedVehiclesApi();
