import fetchCached from './fetchCached';

/**
 * Service for New's Offers API
 */
class AutoServiceNewOffersApi {
  /**
   * Endpoint no contexto de canal
   *
   * @param {string} suffixEndpoint Sufixo do endpoint da request
   * @returns {string} Retorna o endpoint completo
   */
  getChannelEndpoint(suffixEndpoint) {
    const channelId = document.querySelector('meta[name="auto_api_channel"]')
      .content;

    return `/channel/${channelId}${suffixEndpoint}`;
  }

  /**
   * Return all available plans for subscription
   * @return {Promise}
   */
  getOffers(customParams = []) {
    return fetchCached(this.getChannelEndpoint('/new_offers'), customParams);
  }
}

export default new AutoServiceNewOffersApi();
