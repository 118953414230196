import { Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setPerPage,
  setList,
  nextPage,
  incrementList,
} from '../../../../stores/SearchFilter';
import CardCitroen from '../../../Cards/NewsCitroen';
import CardPeugeot from '../../../Cards/NewsPsa';
import PlaceholderCard from '../../../Cards/Placeholder';

class ListNewVehiclesApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMore: false,
      list: [],
    };

    const { perPage } = this.props;

    setPerPage(perPage);
    setList();

    window.store.SearchFilter.watch((state) => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
        showList: !state.showConversionForm,
      });

      this.formatList();
    });
  }

  formatList() {
    const { urlPrefix } = this.props;
    const formattedVehicles = this.state.list.map((vehicle) => {
      return {
        title: vehicle.name,
        image: vehicle.image || vehicle.item_image.middle_image,
        link: urlPrefix + '/' + vehicle.slug,
        price: vehicle.price,
        showPrice: vehicle['show_price?'] && vehicle.showPrice,
      };
    });

    this.setState({
      formattedList: formattedVehicles,
    });
  }

  handleLoadMore(e) {
    e.preventDefault();
    nextPage();
    incrementList();
  }

  fakeList() {
    const totalVehicles = Number(this.props.totalVehicles);
    const fakeArray = Array(totalVehicles).fill(true);
    return fakeArray;
  }

  render() {
    const {
      formattedList,
      shouldLoadMore,
      loadingMore,
      loading,
      showList,
    } = this.state;

    const {
      isPeugeot,
      ctaIcon,
    } = this.props;

    if (showList) {
      return (
        <div class="new-vehicles-listing__content">
          <div class="new-vehicles-listing__list">
            {formattedList.length > 0
              ? formattedList.map((vehicle) => {
                return isPeugeot ?
                  <CardPeugeot
                    class="new-vehicles-listing__item"
                    title={vehicle.title}
                    image={vehicle.image}
                    link={vehicle.link}
                    price={vehicle.price}
                    showPrice={vehicle.showPrice}
                  ></CardPeugeot> :
                  <CardCitroen
                    class="new-vehicles-listing__item"
                    title={vehicle.title}
                    image={vehicle.image}
                    link={vehicle.link}
                    price={vehicle.price}
                    showPrice={vehicle.showPrice}
                    ctaIcon={ctaIcon}
                  ></CardCitroen>
              })
              : this.fakeList().map((_) => <PlaceholderCard />)}
          </div>

          {(shouldLoadMore || loadingMore) && (
            <div class="new-vehicles-listing__load-more-items">
              <a
                href="#"
                class="btn button button--ghost"
                onClick={this.handleLoadMore}
              >
                {loadingMore ? (
                  <div class="new-vehicles__loading">
                    <div className="mr-2 spinner-border small" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                    &nbsp; Carregando...
                  </div>
                ) : (
                  'Carregar mais ofertas'
                )}
              </a>
            </div>
          )}
        </div>
      );
    }
  }
}

ListNewVehiclesApp.defaultProps = {
  perPage: 12,
  list: [],
  formattedList: [],
  totalVehicles: 8,
  urlPrefix: '',
  isPeugeot: false
};
ListNewVehiclesApp.propTypes = {
  perPage: PropTypes.number,
  totalVehicles: PropTypes.number,
  urlPrefix: PropTypes.string,
  isPeugeot: PropTypes.boolean,
};

export default ListNewVehiclesApp;
