import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class NewsCitroenCard extends Component {
  formatted_price() {
    return `R$ ${this.props.price}`
  }

  render() {
    const { title, image, link, showPrice, price, lazy, ctaText, ctaIcon, showIcon } = this.props;

    return (
      <div className="new-citroen-card">
        <a href={link}>
          <img
            className={classNames('new-citroen-card__image', {
              'swiper-lazy': lazy,
            })}
            src={image}
            alt={title}
            width="200"
            height="145"
          />
        </a>
        <h3 className="new-citroen-card__title">{title}</h3>
        {
          showPrice && price && (
            <div class="news-card__price-wrapper">
              <h4 className="news-card__price-call">A partir de:</h4>
              <h3 className="news-card__price">{this.formatted_price()}</h3>
            </div>
          )
        }
        <a className="new-citroen-card__link" href={link}>
          {ctaText} { showIcon && <i class={`icon icon-${ctaIcon}`}></i> }
        </a>
      </div>
    );
  }
}

NewsCitroenCard.defaultProps = {
  title: '',
  image: '',
  link: '',
  price: '',
  showPrice: true,
  showIcon: true,
  ctaText: 'Saiba mais',
  ctaIcon: 'chevron',
  lazy: false,
};

NewsCitroenCard.propTpes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  showIcon: PropTypes.boolean,
  price: PropTypes.string,
  showPrice: PropTypes.boolean,
  ctaText: PropTypes.string,
  ctaIcon: PropTypes.string,
  lazy: PropTypes.boolean,
};
