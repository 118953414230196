import { h, Component } from 'preact'
import VideoPlayer from '../../../auto/components/Video/Player'
import PropTypes from 'prop-types'

export default class GalleryVideo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 0,
      arrowNextRightPosition: 30
    }

    this.players = []

    this.handleInstancePlayer = this.handleInstancePlayer.bind(this)
  }

  componentDidMount() {
    this.initCarousel()
  }

  setEventsPlayer(instance) {
    const { fullscreenOnPlay } = this.props

    instance.on('playing', () => {
      if (fullscreenOnPlay) {
        instance.fullscreen.enter()
      }
    })
  }

  handleInstancePlayer(instance) {
    this.players.push(instance)
    this.setEventsPlayer(instance)
  }

  /**
   * Inicia ou para o vídeo automáticamente ao navegar pelo slide
   *
   * @param {Integer} active index no array do vídeo que seja iniciado
   * @return {Object} retorna um objeto com o index no array do vídeo que será iniciado
   */
  slideTransitionStart(active) {
    this.players[active].play();
    this.players.map(item => item.pause());

    return { active }
  }

  initCarousel() {
    const component = this;
    const carousel = new Carousel(this.ref, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.video-gallery__pagination',
        type: 'bullets',
        clickable: true
      },
      on: {
        slidePrevTransitionStart: function () {
          component.slideTransitionStart(carousel ? carousel.realIndex : 0);
        },
        slideNextTransitionStart: function () {
          component.slideTransitionStart(carousel ? carousel.realIndex : 0);
        },
      },
    });
  }

  getOptions(options) {
    const { controls } = this.props
    let optionsCustom = options

    if (controls.length > 0) {
      optionsCustom = { ...optionsCustom, controls }
    }

    return optionsCustom
  }

  render() {
    const { items } = this.props

    return (
      <div>
        <div ref={node => this.ref = node} className="swiper-container slide">
          <div className="swiper-wrapper">
            { items.map(item => (
              <div className="swiper-slide">
                <VideoPlayer
                  type={item.type}
                  embedId={item.embedId}
                  options={this.getOptions(item.options)}
                  onInstance={this.handleInstancePlayer}
                />
              </div>
            )) }
          </div>
          <div className="swiper-button-prev video-gallery__control video-gallery__control--prev">
            <i className="icon icon-arrow-r"></i>
          </div>
          <div className="video-gallery__pagination"></div>
          <div className="swiper-button-next video-gallery__control video-gallery__control--next">
            <i className="icon icon-arrow-r"></i>
          </div>
        </div>
      </div>
    )
  }
}

GalleryVideo.defaultProps = {
  /*
    Exemplo de uso da prop items:
    items: [
      {
        type: 'vimeo',
        embedId: '182592195', // ou https://vimeo.com/182592195,
        options: {}
      },
      {
        type: 'youtube',
        embedId: 'bTqVqk7FSmY', // ou 'https://www.youtube.com/watch?v=bTqVqk7FSmY',
        options: {}
      },
      {
        type: 'html5',
        options: {
          source: {
            type: 'video',
            poster: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg',
            sources: [
              {
                src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
                type: 'video/mp4'
              }
            ],
          }
        }
      },
    ]
  */
  items: [],
  controls: ['play-large', 'play', 'progress', 'mute', 'volume', 'airplay'],
  fullscreenOnPlay: false
}

GalleryVideo.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['html5', 'youtube', 'vimeo']),
      embedId: PropTypes.string, // url do vídeo do youtube ou vimeo
      options: PropTypes.object // https://github.com/sampotts/plyr#options
    })
  ),
  controls: PropTypes.array, // https://github.com/sampotts/plyr#options,
  fullscreenOnplay: PropTypes.bool
}
