import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class NewsPsaCard extends Component {
  formatted_price() {
    return `R$ ${this.props.price}`
  }

  render() {
    const { title, image, link, lazy, price, showPrice } = this.props;

    return (
      <div className="news-card">
        <a href={link}>
          <img
            className={classNames('news-card__image', {
              'swiper-lazy': lazy,
            })}
            src={image}
            alt={title}
            width="200"
            height="145"
          />
        </a>
        <h3 className="news-card__title">{title}</h3>
        {
          showPrice && price && (
            <div class="news-card__price-wrapper">
              <h4 className="news-card__price-call">A partir de:</h4>
              <h3 className="news-card__price">{this.formatted_price()}</h3>
            </div>
          )
        }
        <a className="news-card__link" href={link}>
          Ver mais <span class="icon-more">+</span>
        </a>
      </div>
    );
  }
}

NewsPsaCard.defaultProps = {
  title: '',
  image: '',
  link: '',
  price: '',
  showPrice: true,
  lazy: false,
};

NewsPsaCard.propTpes = {
  title: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  price: PropTypes.string,
  showPrice: PropTypes.boolean,
  lazy: PropTypes.boolean,
};
