import { createStore, createEffect, createEvent } from 'effector';
import AutoServiceUsedVehiclesApi from '../utils/autoServiceUsedVehicles';

const fetchList = () => {
  const { sort, page, per_page, active } = window.store.UsedVehicles.getState();

  const customParams = [
    { sort },
    { page },
    { per_page },
    { 'q[active_eq]': active },
  ];

  return AutoServiceUsedVehiclesApi.getVehicles(customParams);
};

export const setPerPage = createEvent('set per_page');
export const nextPage = createEvent('next page');
export const setList = createEffect('set list').use(fetchList);
export const incrementList = createEffect('increment list').use(fetchList);

const UsedVehicles = (function () {
  let instance;

  return function (
    defaultValue = {
      sort: 'ordination,-created_at',
      list: [],
      page: 1,
      loading: false,
      loadingMore: false,
      loadInitial: false,
      total_pages: null,
      per_page: 12,
      active: true,
      showList: true,
    }
  ) {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setPerPage, (state, per_page) => ({
        ...state,
        per_page,
      }));

      instance.on(nextPage, (state) => ({
        ...state,
        page: state.page + 1,
      }));

      // setList
      instance.on(setList.pending, (state, loading) => ({
        ...state,
        page: 1,
        loading,
      }));

      instance.on(setList.fail, (state) => ({
        ...state,
        loading: false,
      }));

      instance.on(setList.done, (state, response) => ({
        ...state,
        list: response.result.entries,
        total: response.result.total,
        showList: response.result.total > 0,
        total_pages: response.result.total_pages,
        loading: false,
        loadInitial: true,
      }));

      // incrementList
      instance.on(incrementList.pending, (state, loading) => ({
        ...state,
        loading,
        loadingMore: loading,
      }));

      instance.on(incrementList.done, (state, res) => ({
        ...state,
        list: [...state.list, ...res.result.entries],
        showList: res.result.total > 0,
        loading: false,
        loadingMore: false,
        loadInitial: true,
      }));

      instance.on(incrementList.fail, (state) => ({
        ...state,
        loading: false,
        loadingMore: false,
        page: state.page - 1,
      }));
    }

    return instance;
  };
})();

export default UsedVehicles;
